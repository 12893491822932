const intialState = {
    taskData: [],
    myData:[],
    tempData:[]
}
const taskReducer = (state = intialState, action) => {
    switch (action.type) {
        case 'GETTASK':
            return {
                ...state,
                taskData: action.payload
            }
            case 'SELECTEDDATA':
                return {
                   ...state,
                   myData:action.payload 
                }
                case 'TEMPDATA':
                    return{
                        ...state,
                        tempData:action.payload
                    }

        default:
            return state
    }
}
export default taskReducer