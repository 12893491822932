import { combineReducers } from 'redux';
import taskReducer from '../../redux/reducers/taskreducer';
import AuthReducer from './auth';
import PaginationReducer from './pagination';

const rootReducer = combineReducers({
  auth: AuthReducer,
  pagination: PaginationReducer,
  taskReducer: taskReducer
});

export default rootReducer;
