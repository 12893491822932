
export const getTask = (data) => {
    return {
        type: "GETTASK",
        payload: data
    }
}
export const updateStatus = (data) => {
    return {
        type: "UPDATESTATUS",
        payload: data
    }
}
export const selectedData=(data)=>{
    return {
        type:"SELECTEDDATA",
        payload:data
    }
}
export const tempData=(data)=>{
    return {
        type:"TEMPDATA",
        payload:data

    }
}