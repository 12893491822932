import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Header from './components/Header/Header';
import Login from './pages/Auth/Login/Login';
import Signup from './pages/Auth/Signup/Signup';
import Qadeshboard from './pages/app/AllClaim/AllClaim';
import NotFound from './pages/app/NotFound/NotFound';
import store from './store/store';
import { loadUser } from './store/actions/auth';



const AppRoute = props => {
  const { component: Component, ...rest } = props;
  const { noHeader, nonAuthenticated, freeRoute } = rest;
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  return (
    <Route
      {...rest}
      render={renderProps => {
        const { history } = renderProps;
        if (!freeRoute) {
          if (nonAuthenticated && isAuthenticated) {
            history.push('/app/qadeshboard');
            return null;
          }
          if (!nonAuthenticated && !isAuthenticated) {
            history.push('/login');
            return null;
          }
        }
        return (
          <Fragment>
            {!noHeader && <Header />}
            <Component {...rest} {...renderProps} />
          </Fragment>
        );
      }}
    />  
  );
};
const Routing = () => {
  const [isRendered, setRendered] = useState(false);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  //   return (
  //     <HashRouter>
  //       <Route path="/claim" component={Qadeshboard} exact />
  //       {/* {isAuthenticated  ?
  //         <Route path="/" component={Qadeshboard} exact />
  //         :
  //         <Route path="/login" component={Login} exact />} */}
  //     </HashRouter>
  //   )
  useEffect(() => {
    (async () => {
      const res = await store.dispatch(loadUser());
      
      setRendered(true);
    })();
  }, []);
  if (!isRendered) return null;
  return (
    <HashRouter>
      <ScrollToTop scrollDisabledPathname={[]}>
        <Switch>
          <AppRoute path="/" component={Login} exact noHeader nonAuthenticated />
          <AppRoute path="/login" component={Login} exact noHeader nonAuthenticated />
          <AppRoute path="/signup" component={Signup} exact noHeader nonAuthenticated />
        
          <AppRoute path="/app/qadeshboard" component={Qadeshboard} exact />
          <Route path="*" exact component={NotFound} />
        </Switch>
      </ScrollToTop>
    </HashRouter>
  );
};
AppRoute.propTypes = {
  component: PropTypes.func.isRequired,
};
export default Routing;
