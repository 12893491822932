import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { signup } from '../../../store/actions/auth';
import PrimaryButton from '../../../components/Buttons/PrimaryButton/PrimaryButton';
import MustContainItem from './MustContainItem';
import logo from '../../../assets/images/logo.png';
import logoText from '../../../assets/images/logoText.png';
import '../Common.scss';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [errorConfirmPassword, setErrorConfirmPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [modalShown, setModalShown] = useState(false);
  const [modalErrorText, setModalErrorText] = useState('');
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [ischecked, setchecked] = useState(false);
  const [errorChecked, setCheckPassword] = useState('');

  // booleans for password validations
  const [containsUL, setContainsUL] = useState(false); // uppercase letter
  const [containsLL, setContainsLL] = useState(false); // lowercase letter
  const [containsN, setContainsN] = useState(false); // number
  const [containsSC, setContainsSC] = useState(false); // special character
  const [contains8C, setContains8C] = useState(false); // min 8 characters

  // checks all validations are true
  const [allValid, setAllValid] = useState(false);
  const [showPasswordCheckContainer, setShowPasswordCheckContainer] = useState(false);

  // labels and state boolean corresponding to each validation
  const mustContainData = [
    ['An uppercase letter (a-z)', containsUL],
    ['A lowercase letter (A-Z)', containsLL],
    ['A number (0-9)', containsN],
    ['A special character (!@#$)', containsSC],
    ['Must contain atleast 10 characters', contains8C],
  ];

  const checkEnter = e => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      Checks();
    }
  };

  const Checks = async () => {
    const emailCheck = /\S+@\S+\.\S+/;
    //const decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,15}$/;
    setErrorEmail(null);
    setErrorPassword(null);
    setErrorConfirmPassword(null);
    if (!emailCheck.test(email.trim())) {
      setErrorEmail('Please Enter A Valid Email Address!');
      //} else if (!decimal.test(password.trim())) {
    } 
    else if (!allValid) {
      setErrorPassword(
        'Password must be atleast 10 characters long which contain at least one lowercase letter, one uppercase letter, one numeric digit and one special character'
      );
    } 
    else if (!ischecked) {
      setCheckPassword(
        'Please accept the License and Services Agreement.'
      );
    } 
    else if (!(password === confirmPassword)) {
      setErrorConfirmPassword('Confirm password does not match with password!');
    } else {
      const res = await dispatch(
        signup({
          email,
          password,
        })
      );
      if (res.status) {
        setModalErrorText(
          'You have successfully registered. We have sent an email with a confirmation link to your email address.'
        );
        setSuccess(true);
      } else {
        setModalErrorText(res.message);
        setSuccess(false);
      }
      setModalShown(true);
    }
  };

  const handleModalConfirm = () => {
    if (success) {
      setModalShown(false);
      history.push('/login');
    } else {
      setModalShown(false);
    }
  };

  const activateButton = (e) => {
    alert(e)
    alert(e.checked)
  };
  const validatePassword = () => {
    // has uppercase letter
    if (password.toLowerCase() !== password) setContainsUL(true);
    else setContainsUL(false);

    // has lowercase letter
    if (password.toUpperCase() !== password) setContainsLL(true);
    else setContainsLL(false);

    // has number
    if (/\d/.test(password)) setContainsN(true);
    else setContainsN(false);

    // has special character
    if (/[~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?]/g.test(password)) setContainsSC(true);
    else setContainsSC(false);

    // has 10 characters
    if (password.length >= 10) setContains8C(true);
    else setContains8C(false);

    // all validations passed
    if (containsUL && containsLL && containsN && containsSC && contains8C) setAllValid(true);
    else setAllValid(false);
  };

  return (
    <div id="login" className="main-conatiner">
      <div className="bgImg" />
      <div className="inner-div">
        <div className="main-logo-container">
          <img src={logo} alt="Logo" />
        </div>
        <div className="logo-name">
          <img src={logoText} alt="LogoName" />
        </div>
        <div className="floating-input-div">
          <input
            className="floating-input"
            type="text"
            placeholder="Email"
            onKeyDown={e => checkEnter(e)}
            onChange={e => setEmail(e.target.value)}
            value={email}
          />
          {errorEmail ? (
            <div>
              <p className="ErrorDivEmail">{errorEmail}</p>
            </div>
          ) : null}
        </div>
        <div className="floating-input-div input-container">
          <input
            className="floating-input"
            type={passwordShown ? 'text' : 'password'}
            placeholder="Password"
            onKeyDown={e => checkEnter(e)}
            onChange={e => setPassword(e.target.value)}
            value={password}
            onKeyUp={validatePassword}
            onFocus={() => setShowPasswordCheckContainer(true)}
            onBlur={() => setShowPasswordCheckContainer(false)}
          />
          {passwordShown ? (
            <FaEyeSlash
              className="trailIcon"
              size={30}
              onClick={() => setPasswordShown(pass => !pass)}
            />
          ) : (
            <FaEye
              className="trailIcon"
              size={30}
              onClick={() => setPasswordShown(pass => !pass)}
            />
          )}
          {errorPassword ? (
            <div>
              <p className="ErrorDivEmail">{errorPassword}</p>
            </div>
          ) : null}
          {showPasswordCheckContainer ? (
            <div className="passwordCheckcointener">
              <h4>Password Must Contain:</h4>
              <div className="must-container cfb">
                {mustContainData.map(data => (
                  <MustContainItem data={data} />
                ))}
              </div>
            </div>
          ) : null}

          <input
            className="floating-input"
            type={confirmPasswordShown ? 'text' : 'password'}
            placeholder="Confirm Password"
            onKeyDown={e => checkEnter(e)}
            onChange={e => setConfirmPassword(e.target.value)}
            value={confirmPassword}
            onKeyUp={validatePassword}
          />
          {confirmPasswordShown ? (
            <FaEyeSlash
              className="trailIcon"
              size={30}
              onClick={() => setConfirmPasswordShown(pass => !pass)}
            />
          ) : (
            <FaEye
              className="trailIcon"
              size={30}
              onClick={() => setConfirmPasswordShown(pass => !pass)}
            />
          )}
          {errorConfirmPassword ? (
            <div>
              <p className="ErrorDivEmail">{errorConfirmPassword}</p>
            </div>
          ) : null}
          <div className="termsc">
          <input 
          
          type="checkbox" 
          name="terms" 
          id="terms" 
              checked={ischecked}
          onClick={() => {

                  setchecked(!ischecked);
                  if(ischecked == true){
                    console.log('hey there' + ischecked);
                    setCheckPassword('');
                  }
            
                }}
            />  
          
            <span >I agree to the <a 
            href="https://hostalabs.com/beta/hosta-labs-license-and-services-agreement/" 
            target="_blank" rel="noreferrer">
            License and Services Agreement. </a>
            </span>

            
          </div>
          {(errorChecked && !ischecked) ? (
              <div>
                <p className="ErrorDivEmail chce-error">{errorChecked}</p>
              </div>
            ) : null}
          
        </div>
        <div className="button">
          <Button className="newButton" onClick={Checks}>
            Sign Up
          </Button>
        </div>
        <div className="createAcc">
          <p role="none" className="createAccTxt" onClick={() => history.push('/')}>
            Already have an account?
          </p>
        </div>
      </div>
      <Modal
        show={modalShown}
        onHide={() => setModalShown(false)}
        centered
        animation
        size="md"
        className="shadow-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" />
        </Modal.Header>
        <Modal.Body className="text-center">
          <h3>{modalErrorText}</h3>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <div>
            <PrimaryButton className="new_claims_btn" title="OK" onClick={handleModalConfirm} />
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Login;
