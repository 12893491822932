import React from 'react';
import { Navbar, Nav, NavDropdown, OverlayTrigger } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FiChevronDown } from 'react-icons/fi';
import { logout } from '../../store/actions/auth';
import logo from '../../assets/images/logo.png';
import Tooltip from '../Tooltip/Tooltip';

const Header = () => {
  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleLogout = async () => {
    await dispatch(logout());
  };
  return (
    <div id="header">
      <Navbar variant="dark" className="header_navbar">
        {/* <IoReorderThreeOutline size={40} color="white" /> */}
        <Nav className="ml-auto" id="header-dropdown">
          <NavDropdown title={user ? user.email : 'Username'} id="username-dropdown" alignRight>
            
         
            <NavDropdown.Item onClick={() => handleLogout()}>Logout</NavDropdown.Item>
          </NavDropdown>
          <FiChevronDown className="username_arrow" color="white" size={18} />
        </Nav>
      </Navbar>
      <div className="header_logo--block">
        <div className="hexagon">
          <img
            className="header_logo"
            role="none"
            src={logo}
            alt="icon"
            onClick={() => {
              // history.push('/app/allclaim');
              history.push('/app/qadeshboard');
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
