import React, { useCallback, useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
// import './styles/style.scss';-
import icon1 from '../../../images/Component.svg'
import QualityCheck from '../../../images/QualityCheck.svg'
import QualityCheck2 from '../../../images/QualityCheck2.svg'
import iconnavigationclose from '../../../images/icon-navigation-close.svg'
import accesslink1 from '../../../images/accesslink1.svg'
import accesslink2 from '../../../images/accesslink2.svg'
import accesslink3 from '../../../images/accesslink3.svg'
import upload from '../../../images/upload.svg'
import relabel from '../../../images/relabel.svg'
import approve from '../../../images/approve.svg'
import fail from '../../../images/fail.svg'
import type from '../../../images/type.svg'
import { useState } from 'react';
import { AiOutlineClose } from "react-icons/ai";
import { HiOutlineSortDescending } from "react-icons/hi";
import { Pagination } from 'react-bootstrap';
import { setActiveClaimPage } from '../../../store/actions/pagination';
import supportingimg from '../../../images/Imageplaceholder.png'
import { TiTick } from "react-icons/ti"
import { IoFileTrayStackedSharp } from "react-icons/io5";
import Popup from "../../../popup"
import Popup2 from "../../../popup2"
import PrimaryButton from '../../../components/Buttons/PrimaryButton/PrimaryButton';
import { IoIosSearch } from "react-icons/io";
import { Redirect } from 'react-router';
import { getTask, selectedData } from '../../../redux/action/action';
import { useDropzone } from 'react-dropzone'
import axios from 'axios';
import urls from "../../../env";




const allerrorDisplay = []
const allQAErrors = ["Missing Item", "Extra Item", "Wrong Item", "Wrong Item measurement", "Wrong Wall measurement", "Inconsistent Label", "Not Enough information", "Basic Structure not modeled", "Basic Items not modeled", "Multiple rooms", "Other"]
function Qadeshboard() {
  const formdata = new FormData();
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth);
  const activePagination = useSelector(state => state.pagination.activeClaimPage);
  const [founderror, setFounderror] = useState(false);
  const [founderror2, setFounderror2] = useState(null);
  const [founderror3, setFounderror3] = useState(null);
  const [approved, setapproved] = useState(null);
  const [ifapprove, setIfapprove] = useState(true);
  const [startd, setStartd] = useState(0);
  const [endd, setEndd] = useState(10);
  const [lastIndex, setLastIndex] = useState(10);
  const [DATA, setDATA] = useState(null);
  const [MYDATA, setMYDATA] = useState(null);
  const [Erroname, setErroname] = useState("");
  const [Data, setData] = useState(DATA)
  const [claimpopup, setClaimpopup] = useState(false);
  const [claimpopupFailed, setClaimpopupFailed] = useState(false);
  const [claimedErrorText, setClaimedErrorText] = useState("")
  const [relablepopup, setRelablepopup] = useState(false);
  const [errordata, setErrordata] = useState(null);
  const [comment, setComment] = useState(null);
  const [errorpopup, setErrorpopup] = useState(false);
  const [popupdata, setPopupdata] = useState(null);
  const [changeicon, setChangeicon] = useState(allerrorDisplay);
  const [afterPostErrorSuccess, setafterPostErrorSuccess] = useState(false)
  const [afterPostErrorFail, setafterPostErrorFail] = useState(false)
  const [isShown, setIsShown] = useState(false);
  const [Iconpopup, setIconpopup] = useState(false);
  const [IconId, setIconId] = useState(null);
  const [showmyData, setShowmyData] = useState(false);

  const [showinputfile, setShowinputfile] = useState(false);
  const [showinputdata, setShowinputdata] = useState([]);
  const [responseurldata, setResponseurldata] = useState("");

  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [selectedItemId, setselectedItemId] = useState();
  const [selectedItemData, setselectedItemData] = useState();
  const [activeQaErrors, setActiveQaErrors] = useState(null);
  const [errorPostMessage, setErrorPostMessage] = useState("")
  const [timeSentAsc,setTimeSentAsc] = useState(true);
  const getTASKDATA = useSelector((state) => state.taskReducer.taskData)
  const GETMYDATA = useSelector((state) => state.taskReducer.myData)
  // useEffect(() => {
  //   console.log(getTASKDATA?.numTasks)
  //   if (DATA?.length % 10 == 0) {
  //     setLastIndex(Math.floor(Number(DATA?.length / 10)));
  //   }
  //   else setLastIndex(Math.floor(Number(DATA?.length / 10)) + 1);
  // }, [DATA?.length]);

  useEffect(() => {
    console.log(Math.floor(Number(getTASKDATA?.numTasks / 10)) + 1)
    if (getTASKDATA?.numTasks % 10 === 0) setLastIndex(Math.floor(Number(getTASKDATA?.numTasks / 10)));
    else setLastIndex(Math.floor(Number(getTASKDATA?.numTasks / 10)) + 1);
  }, [getTASKDATA?.numTasks]);


  function approvedTask(id) {
    let final = []
    // setClaimpopup(!claimpopup)
    DATA.filter((cur) => cur.id !== id).map((cur1) => {
      final.push(cur1);
    })
    setDATA(final);
    let result = {
      tasks: final
    }
    console.log(result)
    dispatch(getTask(result))
  }
  // const Deletedata = (data1) => {
  //   setapproved(false)
  //   let index = Data?.indexOf(data1)
  //   Data?.splice(index, 1);
  // }
  const togglePopup = (data) => {

    setErrordata(data);
    console.log("MYDATA", data);
    setFounderror(!founderror);
  }
  const ErrorPopup = (data, type) => {
    if (type == "Modeling") {
      setErroname("Modeling")
    }
    else {
      setErroname("Qa")
    }

    setIconpopup(true)
    if (data) {
      setIconId(data)
    }
    // else{
    //   const value = Object.keys(data.qa);
    // console.log(value)
    //   setIconId(value)
    // }
    // console.log(data)
  }
  const togglePopup2 = (data) => {
    setFounderror2(data);
  }
  const togglePopup3 = (data) => {
    setFounderror3(data);
  }
  const postErrorSuccess = (data) => {
    setafterPostErrorSuccess(true);
  }
  const postErrorError = (data) => {
    setafterPostErrorFail(true);
  }
  const togglePopupforapprove = async (data1) => {
    setapproved(data1);
    let a;
    for (let i = 0; i < data1?.reporterrors?.length; i++) {
      if (data1.reporterrors[i].icon === false) {
        setIfapprove(false);
        a = false;
        break;
      }
      else {
        setIfapprove(true);
        a = true;
      }
    }
    if (a === true) {
      let index = Data?.indexOf(data1);
      await Data?.splice(index, 1);
      console.log(Data);
      setData(Data)
    }
  }
  const copy = (data1) => {
    navigator.clipboard.writeText(data1);
  }
  const handleSort = async type => {
    let newData = [...DATA];
    // if (types[type]) {
    //   console.log(type)
    //   if (type === 'date') {
    //     newData = newData.sort(
    //       (a, b) => new Date(b.timeInitiated * 1000) - new Date(a.timeInitiated * 1000)
    //     );
    //     setClaimData(newData);
    //   }
    //   if (type === 'damage') {
    //     newData = newData.sort((a, b) =>
    //       a.claimInfo.damageType <= b.claimInfo.damageType ? 1 : -1
    //     );
    //     setClaimData(newData);
    //   }
    //   if (type === 'address') {
    //     newData = newData.sort((a, b) =>
    //       a.claimInfo.address1 <= b.claimInfo.address1 ? 1 : -1
    //     );
    //     setClaimData(newData);
    //   }
    //   if (type === 'customerid') {
    //     console.log(newData)
    //     newData = newData.sort((a, b) => (a.customerID <= b.customerID ? 1 : -1));
    //     setClaimData(newData);
    //   }
    //   if (type === 'status') {
    //     newData = newData.sort((a, b) => (a.status <= b.status ? 1 : -1));
    //     setClaimData(newData);
    //   }
    //   setTypes({ ...types, [type]: false });
    //   return;
    // }
    // if (type === 'date') {
    //   newData = newData.sort(
    //     (a, b) => new Date(a.timeInitiated * 1000) - new Date(b.timeInitiated * 1000)
    //   );
    //   setDATA(newData);
    //   // setClaimData(newData);
    // }
    if (type === 'type') {
      newData = newData.sort((a, b) => (b.type <= a.type ? 1 : -1));
      setDATA(newData);
    }
    if (type === 'timeSent') {
      if(timeSentAsc === true){
        newData = newData.sort((a, b) => (b.timeSent <= a.timeSent ? 1 : -1));
        setDATA(newData);
        setTimeSentAsc(false)
      }else{
        newData = newData.sort((a, b) => (b.timeSent >= a.timeSent ? 1 : -1));
        setDATA(newData);
        setTimeSentAsc(true)
      }
    }
    if (type === 'timeLeft') {
      newData = newData.sort((a, b) => (b.timeLeft <= a.timeLeft ? 1 : -1));
      setDATA(newData);
    }
    if (type === 'reportedErrors') {
      newData = newData.sort((a, b) => (b.reportedErrors <= a.reportedErrors ? 1 : -1));
      setDATA(newData);
    }
    if (type === 'foundErrors') {
      newData = newData.sort((a, b) => (b.foundErrors <= a.foundErrors ? 1 : -1));
      setDATA(newData);
    }
    if (type === 'claimedby') {
      newData = newData.sort((a, b) => (b.status <= a.status ? 1 : -1));
      setDATA(newData);
    }
    // if (type === 'claimBy') {
    //   newData = newData.sort((a, b) => (b.type <= a.type ? 1 : -1));
    //   setData(newData);
    // }
    // if (type === 'claimTime') {
    //   newData = newData.sort((a, b) => (b.claimtime <= a.claimtime ? 1 : -1));
    //   setData(newData);
    // }

    // setTypes({ ...types, [type]: true });
  };

  const handlePagination = async page => {
    if (activePagination !== page) {
      // var check = document.getElementById("checkSearch").checked;
      const into10 = (page - 1) * 10;
      console.log(into10)
      setStartd(into10);
      setEndd(into10 + 10);
      // const stats = { start: into10, end: into10 + 10, search: searchText, searchByOrg: check };
      // localStorage.setItem('stats', stats);
      // const allClaimss = await dispatch(getAllClaim(stats));
      dispatch(setActiveClaimPage(page));
    }
  }
  // const handleSearch = async text => {
  //   var check = document.getElementById("checkSearch").checked;vm
  //   handlePagination(1);
  //   const stats = { start: 0, end: 10, search: text, searchByOrg: check };
  //   const allClaimss = await dispatch(getAllClaim(stats));
  // };
  // useEffect(() => {
  //   // dispatch(getTask())
  //   getData(0, 5);
  //   console.log("useselectordat", getTASKDATA);
  // }, []);

  // useEffect(()=>{
  //   dispatch(getTask(tasks))
  // },[])
  useEffect(() => {
    // dispatch(getTask())
    getData(startd, endd);
    console.log("useselectordat", getTASKDATA);
  }, [startd, endd]);
  useEffect(() => {
    if (getTASKDATA) {
      setDATA(getTASKDATA.tasks);
      // setData(getTASKDATA)
    }
  }, [getTASKDATA])
  // useEffect(() => {
  //   if (GETMYDATA){
  //     setDATA(GETMYDATA.tasks);
  //     // setData(getTASKDATA)
  //   }
  // }, [GETMYDATA])
  // useEffect(() => {
  //   Changeddata()
  // }, [GETMYDATA])
  // const Changeddata=()=>{
  //   if (showmyData === true){
  //     setMYDATA(GETMYDATA.tasks);
  //     // setData(getTASKDATA)
  //   }
  // }
  const getData = async (start, end) => {
    let myHeaders = new Headers();
    myHeaders.append("x-api-key", "VARjAAPUke3C7DrAcfHJz9ZCQZBTNmV8yLqfVE66");
    myHeaders.append("Authorization", user.token);
    let requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    fetch(`${urls.ApiUrl}?start=${start}&end=${end}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // setDATA(result);
        dispatch(getTask(result))
      })
      .catch(error => console.log('error', error));
  }
  const claimeUser = (id, name, data) => {
    let temp = {
      tasks: [data]
    }
    dispatch(selectedData(temp))
    setShowmyData(true)
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", "VARjAAPUke3C7DrAcfHJz9ZCQZBTNmV8yLqfVE66");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", user.token);
    var raw = JSON.stringify({ "x-api-key": "VARjAAPUke3C7DrAcfHJz9ZCQZBTNmV8yLqfVE66" });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch(`${urls.ApiUrl}/${id}?status=${name}`, requestOptions)
      .then(response => response.json())
      .then(async (result) => {
        // getData();
        if (result.status) {
          getData(startd, endd)
          //  await Update(id, name)
          console.log(result)
          // if(result.status == "approved"){
          //   await approvedTask(result['id:']);
          // }
          setClaimpopup(!claimpopup)
        }
        else {
          setClaimedErrorText(result.message)
          setClaimpopupFailed(!claimpopupFailed)
        }
      })
      .catch(error => console.log('error', error));
  }

  const onDrop = useCallback(async (acceptedFiles) => {
    // Do something with the files
    // alert(JSON.stringify(showinputdata[1]));
    let temp = showinputdata;
    await formdata.append('key', temp['key']);
    await formdata.append('x-amz-algorithm', temp['x-amz-algorithm']);
    await formdata.append('x-amz-credential', temp['x-amz-credential']);
    await formdata.append('x-amz-date', temp['x-amz-date']);
    await formdata.append('x-amz-security-token', temp['x-amz-security-token']);
    await formdata.append('policy', temp['policy']);
    await formdata.append('x-amz-signature', temp['x-amz-signature']);
    await formdata.append('file', acceptedFiles[0]);
    await uploadfileser(formdata);
  }, [showinputdata])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: '.zip' })

  const showinputdatachnage = async (e) => {
    // e.preventDefault();

    console.log(showinputdata);
    let temp = showinputdata;
    console.log(temp['x-amz-algorithm'])
    await formdata.append('key', temp['key']);
    await formdata.append('x-amz-algorithm', temp['x-amz-algorithm']);
    await formdata.append('x-amz-credential', temp['x-amz-credential']);
    await formdata.append('x-amz-date', temp['x-amz-date']);
    await formdata.append('x-amz-security-token', temp['x-amz-security-token']);
    await formdata.append('policy', temp['policy']);
    await formdata.append('x-amz-signature', temp['x-amz-signature']);
    await formdata.append('file', e.target.files[0]);
    await uploadfileser(formdata);
  }

  const uploadser = (roomid, id, linktype) => {
    setLoadingUpdate(true)
    let myHeaders = new Headers();
    myHeaders.append("x-api-key", "VARjAAPUke3C7DrAcfHJz9ZCQZBTNmV8yLqfVE66");
    myHeaders.append("Authorization", user.token);
    let requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    let romlik = 'c180801b82ca2c6211e876aa615a6d656';
    // fetch(`${urls.ApiUrl}/${id}?roomId=${roomid}&linkType=${linktype}`, requestOptions)
    fetch(`${urls.ApiUrl}/${id}?roomId=${roomid}&linkType=${linktype}`, requestOptions)
      .then(response => response.json())
      .then((blob) => {
        console.log(blob.response_url)
        setResponseurldata(blob.response_url);
        setShowinputdata(blob.fields);
        setShowinputfile(true);
        setLoadingUpdate(false);
        console.log(blob.response_url)
      })
      .catch(error => { console.log('error', error); setLoadingUpdate(false) });
  }
  const uploadfileser = (data) => {
    console.log(data)
    let myHeaders = new Headers();
    console.log(showinputdata)
    let temp = showinputdata
    myHeaders.append("x-api-key", "VARjAAPUke3C7DrAcfHJz9ZCQZBTNmV8yLqfVE66");
    myHeaders.append('content-type', 'multipart/form-data');
    myHeaders.append("Authorization", user.token);
    var raw = JSON.stringify(
      {
        'key': `${temp['key']}`,
        'x-amz-algorithm': `${temp['x-amz-algorithm']}`,
        'x-amz-credential': `${temp['x-amz-credential']}`,
        'x-amz-date': `${temp['x-amz-date']}`,
        'x-amz-security-token': `${temp['x-amz-security-token']}`,
        'policy': `${temp['policy']}`,
        'x-amz-signature': `${temp['x-amz-signature']}`,
        'file': formdata.get("file")
      }
    );

    console.log(raw)
    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: data,
    };
    axios.post(`${responseurldata}`, data, myHeaders)
      .then(response => response.json())
      .then((blob) => {
        console.log(blob)
        claimeUser(selectedItemId, "remodel", selectedItemData);
        setShowinputfile(false);

      })
      .catch(error => {
        console.log('error', error)
        claimeUser(selectedItemId, "remodel", selectedItemData);
        setShowinputfile(false)
      });
  }
  const RelableUser = (id) => {
    setRelablepopup(!relablepopup)
  }
  const showMyData = () => {
    setClaimpopupFailed(!claimpopupFailed)
  }
  const Update = async (id, name) => {
    if (name === "claimed") {
      let singleData = [];
      await DATA.map((data1) => {
        if (data1.id === id) {
          singleData.push({
            ...data1,
            status: "claimed"
          })
        }
        else {
          singleData.push(data1);
        }
      })
      dispatch(getTask({ tasks: singleData }))
    }
    if (name === "relabel") {
      let singleData = [];
      await DATA.map((data1) => {
        if (data1.id === id) {
          singleData.push({
            ...data1,
            status: "relabel"
          })
        }
        else {
          singleData.push(data1);
        }
      })
      dispatch(getTask({ tasks: singleData }))
    }
    if (name === "approved") {
      let singleData = [];
      await DATA.map((data1) => {
        if (data1.id === id) {
          singleData.push({
            ...data1,
            status: "approved"
          })
        }
        else {
          singleData.push(data1);
        }
      })
      dispatch(getTask({ tasks: singleData }))
    }
    if (name === "failed") {
      let singleData = [];
      await DATA.map((data1) => {
        if (data1.id === id) {
          singleData.push({
            ...data1,
            status: "failed"
          })
        }
        else {
          singleData.push(data1);
        }
      })
      dispatch(getTask({ tasks: singleData }))
    }
  }
  const downloadLink = (roomid, id, claimId, linktype) => {
    let myHeaders = new Headers();
    myHeaders.append("x-api-key", "VARjAAPUke3C7DrAcfHJz9ZCQZBTNmV8yLqfVE66");
    // myHeaders.append("Authorization", "VARjAAPUke3C7DrAcfHJz9ZCQZBTNmV8yLqfVE66");
    myHeaders.append("Authorization", user.token);

    let requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    let romlik = 'c180801b82ca2c6211e876aa615a6d656';
    // fetch(`${urls.ApiUrl}/${id}?roomId=${roomid}&linkType=${linktype}`, requestOptions)
    fetch(`${urls.ApiUrl}/${id}?claimId=${claimId}&roomId=${roomid}&linkType=${linktype}`, requestOptions)
      .then(response => response.json())
      .then((blob) => {
        console.log(blob["linkToPortal"])

        if (blob["linkToPortal"] != undefined && blob["linkToPortal"] != "") {
          window.open(blob["linkToPortal"])
        }
        else {

          window.open(blob.link)
        }
        // Create blob link to download
        // const url = window.URL.createObjectURL(
        //   new Blob([blob.link]),
        // );
        // const link = document.createElement('a');
        // link.href = url;
        // link.setAttribute(
        //   'download',
        //   `FileName.pdf`,
        // );
        // // Append to html link element page
        // document.body.appendChild(link);
        // // Start download
        // link.click();
        // // Clean up and remove the link
        // link.parentNode.removeChild(link);
      })
      .catch(error => console.log('error', error));
  }
  const postError = (data) => {
    let ERRORARRAY = []
    changeicon?.map((data) => {
      console.log(data);
      if (data.value === true) {
        ERRORARRAY.push(data.name
        )
      }
    })
    console.log(ERRORARRAY);
    console.log(type);
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", "VARjAAPUke3C7DrAcfHJz9ZCQZBTNmV8yLqfVE66");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", user.token);

    let errors = []
    activeQaErrors?.map((cur) => {
      errors.push(cur)
    })
    var raw = JSON.stringify(
      {
        "errors": errors,
        "comments": comment ? comment : ""
      }
    );
    console.log("raw-------" + raw)
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch(`${urls.ApiUrl}/${data}/errors`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (!result.id) {
          setActiveQaErrors(null);
          postErrorError();
          setFounderror(false)
          setErrorPostMessage(result.message);
        }
        else {
          setErrorPostMessage(result["message:"]);
          getData(startd, endd)
          setComment(null)
          setFounderror(false)
          postErrorSuccess();
          console.log(result);
          setActiveQaErrors(null);
          // let singleData = [];
          //  DATA.map((data1) => {
          //   if (data1.id === data) {
          //     singleData.push({
          //       ...data1,
          //       reportedErrors: {
          //         modeling : data1.reportedErrors.modeling,
          //         qa:{
          //           errors:data1.reportedErrors?.qa?.errors ? data1.reportedErrors?.qa?.errors.concat(activeQaErrors) : activeQaErrors
          //         }
          //       }
          //     })
          //   }
          //   else {
          //     singleData.push(data1);
          //   }
          // })
          // dispatch(getTask({ tasks: singleData }))
        }
      })
      .catch(error => {
        console.log('error', error)
        setActiveQaErrors(null)
      });

  }
  const textareaInput = (event) => {
    const value = event.target.value
    setComment(value)
    console.log(comment);
  }
  const toggleErrorpopup = (data) => {
    setPopupdata(data)
    setErrorpopup(true)
  }
  const selectErrors = (data, comment = "", status) => {
    allQAErrors?.map((error) => {
      if (data === error) {
        if (status === true) {
          let Errors = []
          activeQaErrors?.filter((cur) => Object.keys(cur)[0] !== data).map((curent) => {
            Errors.push(curent);
          })
          setActiveQaErrors(Errors);
          console.log(Errors)
        }
        else {
          if (activeQaErrors?.filter((cur) => Object.keys(cur)[0] === data).length === 0) {

            setActiveQaErrors([...activeQaErrors, { [data]: comment }])
          }
          else {
            let dta = []
            activeQaErrors?.filter((cur) => Object.keys(cur)[0] !== data).map((curent) => {
              dta.push(curent);
            })
            setActiveQaErrors([...dta, { [data]: comment }]);
            console.log(dta)
          }
        }
      }
    })
    // if( data === "e2"){
    //   if(activeQaErrors.includes(data)){
    //     alert("already in")
    //   }
    //   else{
    //     alert("not in")
    //   }
    // }
    // if (id === 1) {
    //   let Errors = []
    //   changeicon.map((cur) => {
    //     if (cur === "frontendError") {
    //       Errors.push([
    //         ...cur,
    //         cur
    //       ])
    //     }
    //     else {
    //       Errors.push(cur);
    //     }
    //   })
    //   console.log(Errors)
    //   setChangeicon(Errors)
    // }
    // if (id === 2) {
    //   let Errors = []
    //   changeicon.map((cur) => {
    //     if (cur.name === "backendError") {
    //       Errors.push({
    //         ...cur,
    //         value: cur.value === true ? false : true
    //       })
    //     }
    //     else {
    //       Errors.push(cur);
    //     }
    //   })
    //   setChangeicon(Errors)
    // }
  }
  return (
    // <Provider store={store}>
    <div className="main">
      {DATA?.length != 0  ? (
        <div className="contain">

          <div className="searchbox">
            {/* <AiOutlineClose className="searchicon" /> */}
            <div className="search-text-main">
              {/* <div className="searchcontain " style={{ display: "flex", flexDirection: "row" }}>
              <PrimaryButton
                className="searchBtn primarybutton"
                id="searchBtn"
                title={<IoIosSearch color="#ffffff" size="24" />}
              />
              <input
                id="searchInp"
                size="42"
                type="text"
                placeholder="Search here"
                className="search-text"

              />
            </div> */}
            </div>
          </div>
          <div className="table">
            <table>
              <tr>
                <th>Room id</th>
                <th ><div className="thcontent"><div className="thcontent1">
                  <HiOutlineSortDescending className="sorticon" onClick={() => { handleSort("type") }} />
                </div>
                  <div className="thcontent2">
                    <p>Type</p>
                  </div>
                </div>
                </th>
                <th style={{ whiteSpace: "pre-line" }}><div className="thcontent"><div className="thcontent1">
                  <HiOutlineSortDescending className="sorticon" onClick={() => { handleSort("timeSent") }} />
                </div>
                  <div className="thcontent2">
                    <p>Time Sent</p>
                  </div>
                </div></th>
                <th style={{ whiteSpace: "pre-line" }} ><div className="thcontent"><div className="thcontent1">
                  <HiOutlineSortDescending className="sorticon" onClick={() => { handleSort("timeLeft") }} />
                </div>
                  <div className="thcontent2">
                    <p>Time Left</p>
                  </div>
                </div></th>
                <th ><div className="thcontent"><div className="thcontent1">
                  <HiOutlineSortDescending className="sorticon" onClick={() => { handleSort("reportedErrors") }} />
                </div>
                  <div className="thcontent2">
                    <p>Reported Error</p>
                  </div>
                </div> </th>
                {DATA && DATA[0] ?.status && DATA[0] ?.status !== "claimable" && getTASKDATA.organization !== "hostalabs" &&
                  <>
                    <th><div className="thcontent"><div className="thcontent1">
                      <HiOutlineSortDescending className="sorticon" onClick={() => { handleSort("foundErrors") }} />
                    </div>
                      <div className="thcontent2">
                        <p>Found Errors</p>
                      </div>
                    </div>
                    </th>
                    <th>Access Links</th>
                  </>
              }
                <th><div className="thcontent">
                  <div className="thcontent1"><HiOutlineSortDescending className="sorticon" onClick={() => { handleSort("claimedby") }} /></div>
                  <div className="thcontent2"><p>Claimed By</p></div>
                </div>
                </th>
                {DATA && DATA[0] ?.status && DATA[0] ?.status !== "claimable" && getTASKDATA.organization !== "hostalabs" &&
                  <>
                    <th>
                      <div className="thcontent">
                        <div className="thcontent1"><HiOutlineSortDescending className="sorticon" onClick={() => { handleSort("claimBy") }} /></div>
                        <div className="thcontent2"><p>Claimed Time</p></div>
                      </div>
                    </th>
                    <th className="npspacforheader">Upload</th>
                    <th className="npspacforheader">Relabel</th>
                    <th className="npspacforheader">Approve &nbsp;</th>
                    <th className="npspacforheader">Failed</th>
                  </>
              }
              </tr>
              {/* {
              Data.map((data, i) => {
                if (i >= startd && i < endd) {
                  return (
                    <tr className="bordertr">
                      <td style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}>
                        <img src={icon1} />{data.id}...<img onClick={() => { copy(data.id) }} src={type} /></td>

                      <td>{data.type}</td>
                      <td>{data.timesent}</td>
                      <td>{data.timeleft}</td>
                      <td> {
                        data.reporterrors.map((elem) => {
                          let img;
                          if (elem.icon === true) {
                            img = <img src={QualityCheck} />
                          }
                          else {
                            img = <img src={QualityCheck2} />
                          }
                          return (
                            img

                          )
                        })
                      }
                      </td>
                      <td><img src={iconnavigationclose}
                        onClick={() => togglePopup(data)}
                      /></td>


                      <td className="accesslink"><img src={accesslink1} />
                        <img src={accesslink2} />
                        <img src={accesslink3} /></td>
                      <td><button>{data.claimedby}</button></td>
                      <td>{data.claimtime}</td>
                      <td className="npspac"><img onClick={togglePopup2} className="npspacimg" src={upload} /></td>
                      <td className="npspac"><img className="npspacimg" src={relabel} /></td>
                      <td className="npspac"><img className="npspacimg" onClick={() => { togglePopupforapprove(data) }} src={approve} /></td>
                      <td className="npspac"><img className="npspacimg" onClick={togglePopup3} src={fail} /></td>
                    </tr>
                  )
                }
              })
            } */}
              {
                DATA ?.map((data, i) => {
                  if (data && data.status && data.roomId && data.roomId !== "" && getTASKDATA.organization === "hostalabs") {
                    return (
                      <tr className="bordertr">
                        <td style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}>
                          <img src={icon1} /><p className="shortid">{data.roomId}</p><img onClick={() => { copy(data.roomId) }} src={type} /></td>
                        <td>{data.type}</td>
                        {/* <td>{data.type}</td> */}
                        <td>{new Date(Number(data.timeSent * 1000)).toLocaleString()}</td>
                        {
                          data.timeLeft !== "" ? (
                            <td>{data.timeLeft}</td>
                          ) : <td></td>}

                        <td>
                          {data ?.reportedErrors ?.modeling ?.errors ?.length > 0 &&
                            <button onClick={() => {
                              ErrorPopup(data ?.reportedErrors ?.modeling, "Modeling")
                            }}>Model</button>
                          // <img onClick={()=>{
                          // ErrorPopup(data?.reportedErrors?.modeling,"Modeling")
                          // }
                          // } src={QualityCheck2}/>
                        }
                          {data ?.reportedErrors ?.qa ?.errors.length > 0 &&
                            <button onClick={() => {
                              ErrorPopup(data ?.reportedErrors ?.qa, "Qa")
                            }}>Qa</button>
                          // <img onClick={()=>{
                          //   ErrorPopup(data?.reportedErrors?.qa,"Qa")
                          // }
                          // } src={QualityCheck2}/>
                        }
                        </td>
                        {data.status === "claimable" || data.status === "claimed" ? null
                          :
                          <>
                            <td>
                              <img src={iconnavigationclose}
                                onClick={() => {
                                  if (data.status !== "claimable" || data.status !== "claimed") {
                                    let activeErr = [];
                                    if (data ?.reportedErrors ?.qa ?.errors.length > 0) {
                                      data ?.reportedErrors ?.qa ?.errors ?.map((cur) => {
                                        let key = Object.keys(cur)[0]
                                        activeErr.push({ [Object.keys(cur)[0]]: cur[`${key}`] })
                                      })
                                    setActiveQaErrors(activeErr)
                                    }
                                    else {
                                      setActiveQaErrors([])
                                    }
                                    if (data ?.reportedErrors ?.qa ?.comments !== "") {
                                      setComment(data ?.reportedErrors ?.qa ?.comments)
                                    }
                                    else {
                                      setComment()
                                    }
                                    togglePopup(data);
                                  }
                                }}
                              />
                            </td>
                            <td className="accesslink"><img onClick={() => {
                              downloadLink(data.roomId, data.id, data.claimId, "portal")
                            }}
                              src={accesslink1} />
                              <img onClick={() => {
                                downloadLink(data.roomId, data.id, data.claimId, "downloadInstruction")
                              }} src={accesslink2} />
                              <img onClick={() => {
                                downloadLink(data.roomId, data.id, data.claimId, "downloadModeling")
                              }} src={accesslink3} />
                            </td>
                          </>
                        }
                        <td>
                          {data.status === "claimable" ?
                            <button onClick={() => {
                              claimeUser(data.id, "claimed", data)
                            }}>{data.status}</button>
                            :
                            <button onClick={() => {
                              claimeUser(data.id, "unclaimed", data)
                            }}>UnClaim</button>
                          }
                        </td>

                        {
                          data.status !== "claimable" && data.status !== "claimed"   && data.claimTime !== "" ? (
                            <td>{new Date(Number(data.claimTime * 1000)).toLocaleString()}</td>
                          ) : <td></td>}
                        {data.status === "claimable" || data.status === "claimed" ? null
                          :
                          <>
                            <td className="npspac">
                              <img onClick={() => data.status !== "claimable" ? togglePopup2 : null} className="npspacimg"
                                src={upload}

                                onClick={() => { uploadser(data.roomId, data.id, "upload"); setselectedItemId(data.id); setselectedItemData(data); }}
                                style={data.status !== "claimable" ? { opacity: 1 } : { opacity: 0.5 }} />
                              {loadingUpdate === true ? <p>Loading...</p> : null}
                            </td>
                            {/* {showinputfile == true &&
                        <div>
                          <input type='file' onChange={showinputdatachnage} ></input>
                        </div>
                        } */}
                            <td className="npspac">
                              <img className="npspacimg" src={relabel} onClick={() => data.status !== "claimable" ? data.status === "relabel" ? null : claimeUser(data.id, "relabel") : null}
                                style={data.status !== "claimable" ? data.status === "relabel" ? { opacity: 0.5 } : { opacity: 1 } : { opacity: 0.5 }}
                              />
                            </td>
                            {/* {relablepopup !== false && <Popup
                        content={
                          <div className="resloved">
                            <>
                              <div className="closeresolved">
                                <AiOutlineClose onClick={() => { setRelablepopup(!relablepopup) }} className="closeicon" />
                              </div>
                              <div className="contentresolved">
                                <h5>Thank you for Relabling</h5>
                              </div>
                            </>
                          </div>
                        }
                        handleClose={togglePopupforapprove}
                      />} */}
                            <td className="npspac"><img className="npspacimg" onClick={() => data.status !== "claimable" ? data.status === "approved" ? null : claimeUser(data.id, "approved") : null} src={approve}
                              style={data.status !== "claimable" ? data.status === "approved" ? { opacity: 0.5 } : { opacity: 1 } : { opacity: 0.5 }}
                            /></td>
                            <td className="npspac"><img className="npspacimg" onClick={() => data.status !== "claimable" ? data.status === "failed" ? null : claimeUser(data.id, "failed") : null} src={fail}
                              style={data.status !== "claimable" ? data.status === "failed" ? { opacity: 0.5 } : { opacity: 1 } : { opacity: 0.5 }}
                            /></td>
                          </>
                        }

                      </tr>
                    )
                  }
                  else {
                    return (
                      <tr className="bordertr">
                        <td style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}>
                          <img src={icon1} /><p className="shortid">{data.roomId}</p><img onClick={() => { copy(data.roomId) }} src={type} /></td>
                        <td>{data.type}</td>
                        {/* <td>{data.type}</td> */}
                        <td>{new Date(Number(data.timeSent * 1000)).toLocaleString()}</td>
                        {
                          data.timeLeft !== "" ? (
                            <td>{data.timeLeft}</td>
                          ) : <td></td>}

                        <td>
                          {data ?.reportedErrors ?.modeling ?.errors ?.length > 0 &&
                            <button onClick={() => {
                              ErrorPopup(data ?.reportedErrors ?.modeling, "Modeling")
                            }}>Model</button>
                          // <img onClick={()=>{
                          // ErrorPopup(data?.reportedErrors?.modeling,"Modeling")
                          // }
                          // } src={QualityCheck2}/>
                        }
                          {data ?.reportedErrors ?.qa ?.errors.length > 0 &&
                            <button onClick={() => {
                              ErrorPopup(data ?.reportedErrors ?.qa, "Qa")
                            }}>Qa</button>
                          // <img onClick={()=>{
                          //   ErrorPopup(data?.reportedErrors?.qa,"Qa")
                          // }
                          // } src={QualityCheck2}/>
                        }
                        </td>
                        {data.status === "claimable" ? null
                          :
                          <>
                            <td>
                              <img src={iconnavigationclose}
                                onClick={() => {
                                  if (data.status !== "claimable") {
                                    let activeErr = [];
                                    if (data ?.reportedErrors ?.qa ?.errors.length > 0) {
                                      data ?.reportedErrors ?.qa ?.errors ?.map((cur) => {
                                        let key = Object.keys(cur)[0]
                                        activeErr.push({ [Object.keys(cur)[0]]: cur[`${key}`] })
                                      })
                                    setActiveQaErrors(activeErr)
                                    }
                                    else {
                                      setActiveQaErrors([])
                                    }
                                    if (data ?.reportedErrors ?.qa ?.comments !== "") {
                                      setComment(data ?.reportedErrors ?.qa ?.comments)
                                    }
                                    else {
                                      setComment()
                                    }
                                    togglePopup(data);
                                  }
                                }}
                              />
                            </td>
                            <td className="accesslink"><img onClick={() => {
                              downloadLink(data.roomId, data.id, data.claimId, "portal")
                            }}
                              src={accesslink1} />
                              <img onClick={() => {
                                downloadLink(data.roomId, data.id, data.claimId, "downloadInstruction")
                              }} src={accesslink2} />
                              <img onClick={() => {
                                downloadLink(data.roomId, data.id, data.claimId, "downloadModeling")
                              }} src={accesslink3} />
                            </td>
                          </>
                        }
                        <td>
                          {data.status === "claimable" ?
                            <button onClick={() => {
                              claimeUser(data.id, "claimed", data)
                            }}>{data.status}</button>
                            :
                            <button onClick={() => {
                              claimeUser(data.id, "unclaimed", data)
                            }}>UnClaim</button>
                          }
                        </td>

                        {
                          data.status !== "claimable" && data.claimTime !== "" ? (
                            <td>{new Date(Number(data.claimTime * 1000)).toLocaleString()}</td>
                          ) : <td></td>}
                        {data.status === "claimable" ? null
                          :
                          <>
                            <td className="npspac">
                              <img onClick={() => data.status !== "claimable" ? togglePopup2 : null} className="npspacimg"
                                src={upload}

                                onClick={() => { uploadser(data.roomId, data.id, "upload"); setselectedItemId(data.id); setselectedItemData(data); }}
                                style={data.status !== "claimable" ? { opacity: 1 } : { opacity: 0.5 }} />
                              {loadingUpdate === true ? <p>Loading...</p> : null}
                            </td>
                            {/* {showinputfile == true &&
                        <div>
                          <input type='file' onChange={showinputdatachnage} ></input>
                        </div>
                        } */}
                            <td className="npspac">
                              <img className="npspacimg" src={relabel} onClick={() => data.status !== "claimable" ? data.status === "relabel" ? null : claimeUser(data.id, "relabel") : null}
                                style={data.status !== "claimable" ? data.status === "relabel" ? { opacity: 0.5 } : { opacity: 1 } : { opacity: 0.5 }}
                              />
                            </td>
                            {/* {relablepopup !== false && <Popup
                        content={
                          <div className="resloved">
                            <>
                              <div className="closeresolved">
                                <AiOutlineClose onClick={() => { setRelablepopup(!relablepopup) }} className="closeicon" />
                              </div>
                              <div className="contentresolved">
                                <h5>Thank you for Relabling</h5>
                              </div>
                            </>
                          </div>
                        }
                        handleClose={togglePopupforapprove}
                      />} */}
                            <td className="npspac"><img className="npspacimg" onClick={() => data.status !== "claimable" ? data.status === "approved" ? null : claimeUser(data.id, "approved") : null} src={approve}
                              style={data.status !== "claimable" ? data.status === "approved" ? { opacity: 0.5 } : { opacity: 1 } : { opacity: 0.5 }}
                            /></td>
                            <td className="npspac"><img className="npspacimg" onClick={() => data.status !== "claimable" ? data.status === "failed" ? null : claimeUser(data.id, "failed") : null} src={fail}
                              style={data.status !== "claimable" ? data.status === "failed" ? { opacity: 0.5 } : { opacity: 1 } : { opacity: 0.5 }}
                            /></td>
                          </>
                        }

                      </tr>
                    )
                  }
                })
            }
            </table>
            {claimpopup !== false && <Popup2
              content={
                <div className="resloved lessWidthPopup">
                  <>
                    <div className="closeresolved">
                      <AiOutlineClose onClick={() => { setClaimpopup(!claimpopup) }} className="closeicon" />
                    </div>
                    <div className="contentresolved">
                      <h5>Thank you for Updating Status</h5>
                    </div>
                  </>

                </div>
              }
              handleClose={togglePopupforapprove}
            />}
            {claimpopupFailed !== false && <Popup2
              content={
                <div className="resloved lessWidthPopup">
                  <>
                    <div className="closeresolved">
                      <AiOutlineClose onClick={showMyData} className="closeicon" />
                    </div>
                    <div className="contentresolved">
                      <h5>{claimedErrorText}</h5>
                    </div>
                  </>

                </div>
              }
              handleClose={togglePopupforapprove}
            />}
            {isShown === true &&
              <div>
                gfhgfhj
            </div>
            }
            {founderror !== false && <Popup
              content={
                DATA ?.filter((data) => errordata.id === data.id).map((allerr) => {
                  // console.log('MYERROR', allerr.reportedErrors.qa);
                  return (
                    <div>
                      <div>

                        {/* {allerr?.reportedErrors?.qa == undefined ? (
                      null
                    ) : <p className="palreadysummited">QA Already submmited</p>} */}

                      </div>
                      <div className="popupcontent">
                        <div className="selecterrors foundErrorsTable">
                          <h5>Please Indicate Errors You Found </h5>
                          <p>Please check one Or multiple that applies</p>
                          <table>
                            <tr>
                              <th>Error Type</th>
                              <th>Description</th>
                            </tr>
                            {
                              allQAErrors ?.map((cur) => {
                                if (activeQaErrors ?.filter((fil) => Object.keys(fil)[0] == cur).length > 0) {
                                  let key = ""
                                  activeQaErrors ?.filter((fil) => Object.keys(fil)[0] == cur).map((cur1) => {
                                    key = cur1[Object.keys(cur1)[0]]
                                  })
                                return (
                                    <tr>
                                      {/* <div style={{margin:"7px",textAlign:"left"}}> */}
                                      <td>
                                        <img onClick={() => selectErrors(cur, "", true)} style={{ width: "20px" }} className="additemicons" src={QualityCheck} /> <span>{cur}</span>
                                        {/* <p>{key}</p> */}
                                      </td>
                                      <td>
                                        <input type="text" value={key} onChange={(e) => { selectErrors(cur, e.target.value, false) }} placeholder="Enter Description" />
                                        {/* </div> */}
                                      </td>
                                    </tr>
                                  )
                                }
                                else {
                                  return (
                                    <tr>
                                      {/* <div style={{margin:"7px",textAlign:"left"}}> */}
                                      <td>
                                        <img onClick={() => selectErrors(cur, "", false)} style={{ width: "20px" }} className="additemicons" src={QualityCheck2} /> <span>{cur}</span>
                                        {/* <p>{cur}</p> */}
                                      </td>
                                      {/* </div> */}
                                    </tr>
                                  )
                                }
                              })
                          }
                          </table>
                        </div>
                        <div className="addcomments">
                          <div>
                            <AiOutlineClose onClick={() => { togglePopup(!founderror); setActiveQaErrors(null); }} className="closeicon" />
                          </div>
                          <div>
                            <textarea onChange={(e) => { setComment(e.target.value) }
                              // textareaInput
                            } value={comment} placeholder="Add comments" rows="12" cols="49 ">
                            </textarea>
                          </div>
                          <div>
                            {/* {allerr?.reportedErrors?.qa == undefined ? (
                          <button onClick={() => {
                            postError(allerr.id)
                          }} className="savebutton">SAVE</button>
                        ) : <button className="savebutton" >SAVE</button>} */}
                            <button onClick={() => {
                              postError(allerr.id)
                            }} className="savebutton">SAVE</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
            }
            />
            }
            {Iconpopup !== false && <Popup
              classerror={Erroname}
              content={
                <div className="resloved ShowErrorPopup">

                  {Erroname === "Qa" ?
                    IconId ?
                      <div style={{ textAlign: "left", width: "70%" }}>
                        <h2>{Erroname} Errors</h2>
                        {/* <div style={{display:"flex",flexDirection:"row"}}> */}
                        <table>
                          <tr>
                            <th>Error Type</th>
                            <th>Description</th>
                          </tr>{
                            allQAErrors ?.map((cur) => {
                              if (IconId ?.errors ?.filter((fil) => Object.keys(fil)[0] == cur).length > 0) {
                                return (
                                  <tr>
                                    {/* <div style={{margin:"7px",textAlign:"left",display:"flex",alignItems:"center"}}> */}
                                    <td>
                                      <img style={{ width: "20px" }} className="additemicons" src={QualityCheck} /> <span>{cur}</span>
                                    </td>
                                    <td>
                                      {IconId ?.errors ?.filter((fil) => Object.keys(fil)[0] == cur).map((cur1) => { return <span >{cur1[`${cur}`]}</span> })}
                                    </td>
                                    {/* </div> */}
                                  </tr>
                                )
                              }
                              else {
                                return (
                                  <tr>
                                    {/* <div style={{margin:"7px",textAlign:"left"}}> */}
                                    <td>
                                      <img style={{ width: "20px" }} className="additemicons" src={QualityCheck2} /> <span>{cur}</span>
                                    </td>
                                    {/* <p>{cur}</p> */}
                                    {/* </div> */}
                                  </tr>
                                )
                              }
                            })
                        }
                        </table>
                        {/* { IconId?.comment &&
                  <div style={{flex:3}}>
                    <h4>Comment: </h4>
                    <p>{IconId?.comment}</p>
                  </div>
               } */}
                        {/* </div> */}
                      </div>
                      :
                      <div style={{ textAlign: "left" }}>
                        <h2>Errors</h2>
                        {
                          allQAErrors.map((cur) => {
                            return (
                              <div style={{ margin: "7px", textAlign: "left" }}>
                                <img style={{ width: "20px" }} className="additemicons" src={QualityCheck2} /> <span>{cur}</span>
                                <p>{cur}</p>
                              </div>
                            )
                          })
                        }
                      </div>
                    :
                    IconId ?
                      <div style={{ textAlign: "left", width: "70%" }}>
                        <h2>{Erroname} Errors</h2>
                        <table>
                          <tr>
                            <th>Error Type</th>
                            <th>Description</th>
                          </tr>
                          {
                            IconId ?.errors ?.map((cur, i) => {
                              // if (IconId?.errors?.filter((fil) => Object.keys(fil)[0] == cur).length > 0) {
                              return (
                                <tr>
                                  {/* <div style={{margin:"7px",textAlign:"left",display:"flex",alignItems:"center"}}> */}
                                  <td>
                                    <img style={{ width: "20px" }} className="additemicons" src={QualityCheck} />
                                    <span> {cur.type}</span>
                                  </td>
                                  <td>
                                    <span>{cur.comments}</span>
                                    {/* {IconId?.errors?.filter((fil) => Object.keys(fil)[0] == cur).map((cur1) => {  })} */}
                                  </td>
                                  {/* </div> */}
                                </tr>
                              )
                              // }
                              // else {
                              //   return (
                              //     <tr>
                              //       {/* <div style={{margin:"7px",textAlign:"left"}}> */}
                              //       <td>
                              //         <img style={{ width: "20px" }} className="additemicons" src={QualityCheck2} /> <span>{cur}</span>
                              //       </td>
                              //       {/* <p>{cur}</p> */}
                              //       {/* </div> */}
                              //     </tr>
                              //   )
                              // }
                            })
                        }
                        </table>
                        {/* { IconId?.comments &&
                  <div>
                    <p>{IconId?.comments}</p>
                  </div>
               } */}
                      </div>
                      :
                      <div style={{ textAlign: "left" }}>
                        <h2>Errors</h2>
                        {
                          IconId ?.errors ?.map((cur) => {
                            return (
                              <div style={{ margin: "7px", textAlign: "left" }}>
                                <img style={{ width: "20px" }} className="additemicons" src={QualityCheck2} /> <span>{cur.type}</span>
                                {/* <p>{cur}</p> */}
                              </div>
                            )
                          })
                      }
                      </div>
                  }
                  <div className="addcomments">
                    <div>
                      <AiOutlineClose onClick={() => { setIconpopup(!Iconpopup) }} className="closeicon" />
                    </div>
                    <div>
                      <textarea value={IconId ?.comments !== "" ? IconId ?.comments : "no comments posted"} placeholder="Add comments" disabled rows="12" cols="49 ">
                      </textarea>
                    </div>
                    <div>
                      {/* {allerr?.reportedErrors?.qa == undefined ? (
                          <button onClick={() => {
                            postError(allerr.id)
                          }} className="savebutton">SAVE</button>
                        ) : <button className="savebutton" >SAVE</button>} */}
                      {/* <button onClick={() => {
                            postError(allerr.id)
                          }} className="savebutton">SAVE</button> */}
                    </div>
                  </div>
                </div>
              }
              handleClose={togglePopupforapprove}
            />}
            {afterPostErrorSuccess !== false && <Popup2
              content={
                <div className="resloved">
                  <>
                    <div className="closeresolved">
                      <AiOutlineClose onClick={() => { setafterPostErrorSuccess(!afterPostErrorSuccess); setFounderror(false) }} className="closeicon" />
                    </div>
                    <div className="contentresolved">
                      <h5>{errorPostMessage}</h5>
                    </div>
                  </>

                </div>
              }
              handleClose={togglePopupforapprove}
            />}
            {afterPostErrorFail !== false && <Popup2
              content={
                <div className="resloved">
                  <>
                    <div className="closeresolved">
                      <AiOutlineClose onClick={() => { setafterPostErrorFail(!afterPostErrorFail); setFounderror(false) }} className="closeicon" />
                    </div>
                    <div className="contentresolved">
                      <h5>{errorPostMessage}</h5>
                    </div>
                  </>

                </div>
              }
              handleClose={togglePopupforapprove}
            />}
            {founderror2 !== null && <Popup
              content={
                <div className="founderror2">
                  <div>
                    <AiOutlineClose onClick={() => togglePopup2(null)} className="closeicon2" />
                  </div>
                  <div className="missingtitle">
                    <div style={{ width: "50%" }}>
                      <h5>Missing Item</h5>
                    </div>
                  </div>
                  <div className="missingcontent">
                    <div className="missingcontentrow1">
                      <h4>Comment by 3D vender</h4>
                      <hr />
                      <p >Label is missing the refrigerator</p>
                    </div>
                    <div className="missingcontentrow2">
                      <h4>Supporting images</h4>
                      <hr />
                      <div className="supportingimages">
                        <img src={supportingimg} />
                        <img src={supportingimg} />
                        <img src={supportingimg} />
                        <img src={supportingimg} />
                        <img src={supportingimg} />
                        <img src={supportingimg} />
                      </div>
                    </div>
                  </div>
                </div>
              }
            // handleClose={togglePopup}
            />}
            {founderror3 !== null && <Popup
              content={<div className="popupcontent">
                <div className="selecterrors">
                  <p>Which of the folloing issues have you resolved by uploading </p>
                  <p style={{ fontSize: "15px" }}>Please check one Or multiple that applies</p>
                  <ul>
                    <li><img className="additemicons" src={QualityCheck} />Inconsistent Number</li>
                    {/* {
                    items.map((item) => {
                      return (
                        <li><img className="additemicons" src={QualityCheck2} /> {item.item}</li>
                      )
                    })
                  } */}
                  </ul>
                </div>
                <div className="addcomments">
                  <div >
                    <AiOutlineClose onClick={() => togglePopup3(null)} className="closeicon" />
                  </div>
                  <div>
                    <p>Please check or comment what you fixed</p>
                  </div>
                  <div className="checkicons">
                    <div className="checkiconcontent">
                      <TiTick className="checkicon" />
                      &nbsp; Corrected CSV
                  </div>
                    <div className="checkiconcontent">
                      <TiTick />
                      &nbsp; Change Materials
                  </div>
                    <div className="checkiconcontent">
                      <TiTick />
                      &nbsp; Remodel items
                  </div>

                    <div className="checkiconcontent">
                      <TiTick />
                      &nbsp; Resend to labeling
                  </div>
                    <div className="checkiconcontent">
                      <TiTick />
                      &nbsp; Renumber items
                  </div>
                    <div className="checkiconcontent">
                      <TiTick />
                      &nbsp; Adjust measurement
                  </div>

                  </div>
                  <div>
                    <textarea placeholder="Add comments" rows="08" cols="49 ">
                    </textarea>
                  </div>
                  <div className="resolvebutton">
                    <button className="savebutton">RESOLVE ISSUE</button>
                  </div>
                </div>
              </div>}
            // handleClose={togglePopup}
            />}
            {/* {founderror !== false && <Popup
            content={
              DATA.tasks.filter((data) => errordata.roomId === data.roomId).map((allerr) => {
                console.log('MYERROR', allerr);
                return (
                  <div className="popupcontent">
                    <div className="selecterrors">
                      <h5>Please Indicate Errors You Found </h5>
                      <p>Please check one Or multiple that applies</p>
                      <ul>
                        <li><img className="additemicons" src={QualityCheck} />Inconsistent Number</li>
                        {allerr.reportedErrors.map((err) => {
                          return (
                            <li>{err}</li>
                          )
                        })}
                      </ul>
                    </div>
                    <div className="addcomments">
                      <div>
                        <AiOutlineClose onClick={() => togglePopup(!founderror)} className="closeicon" />
                      </div>
                      <div>
                        <textarea onChange={
                          textareaInput
                        } placeholder="Add comments" rows="12" cols="49 ">
                        </textarea>
                      </div>
                      <div>
                        <button onClick={() => {
                          postError(allerr)
                        }} className="savebutton">SAVE</button>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          />} */}
            {errorpopup !== false && <Popup
              content={
                DATA.map((data) => {
                  return data.reportedErrors.filter((data) => data === popupdata).map((data) => {
                    console.log(popupdata);
                    <div className="popupcontent">
                      <div className="selecterrors">
                        <h5>Please Indicate Errors You Found </h5>
                        <p>Please check one Or multiple that applies</p>
                        <ul>
                          <li><img className="additemicons" src={QualityCheck} />Inconsistent Number</li>
                          <li>{data.type1, console.log(data.type1)}</li>
                        </ul>
                      </div>
                      <div className="addcomments">
                        <div>
                          <AiOutlineClose onClick={() => setErrorpopup(!errorpopup)} className="closeicon" />
                        </div>
                        <div>
                          <textarea onChange={
                            textareaInput
                          } placeholder="Add comments" rows="12" cols="49 ">
                          </textarea>
                        </div>
                        <div>
                          <button onClick={() => {
                            // postError(allerr)
                          }} className="savebutton">SAVE</button>
                        </div>
                      </div>
                    </div>
                  })
                })
              }
            />}
            {approved !== null && <Popup
              content={
                <div className="resloved">
                  {
                    ifapprove === false ?
                      <>
                        <div className="closeresolved">
                          <AiOutlineClose onClick={() => { setapproved(null) }} className="closeicon" />
                        </div>
                        <div className="contentresolved">
                          <h5>You Can't approve unless all issues has been resolved</h5>
                        </div>
                      </>
                      :
                      <>
                        <div className="closeresolved">
                          <AiOutlineClose onClick={() => { setapproved(null) }} className="closeicon" />
                        </div>
                        <div className="contentresolved">
                          <h5>Your issues has been approved</h5>
                        </div>
                      </>
                  }
                </div>
              }
              handleClose={togglePopupforapprove}
            />}
          </div>
          {lastIndex > 1 ? (
            <div id="ClaimsPagination">
              <Pagination>
                <Pagination.First active={false} onClick={() => handlePagination(1)} />
                {/* <Pagination.Prev
                active={false}
                onClick={() => handlePagination(activePagination - 1)}
              /> */}
                {[...Array(lastIndex)].map((i, number) => (
                  <Pagination.Item
                    key={`allcalim_paginate_${number + 1}`}
                    onClick={() => handlePagination(number + 1)}
                    active={number + 1 === activePagination}
                  >
                    {number + 1}
                  </Pagination.Item>
                ))}
                {/* <Pagination.Next
                active={false}
                onClick={() => handlePagination(activePagination + 1)}
              /> */}
                <Pagination.Last active={false} onClick={() => handlePagination(lastIndex)} />
              </Pagination>
            </div>
          ) : null}
          {showinputfile == true &&
            <Popup
              content={
                <div className="resloved">
                  <>
                    <div className="closeresolved">
                      <AiOutlineClose onClick={() => { setShowinputfile(false) }} className="closeicon" />
                    </div>
                    <div className="contentresolved">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <IoFileTrayStackedSharp className='dropzoneIcon' />
                        {
                          isDragActive ?
                            <p>Drop the files here ...</p> :
                            <p>Drag 'n' drop some files here, or click to select files</p>
                        }
                      </div>
                    </div>
                  </>

                </div>
              }
              handleClose={togglePopupforapprove}
            />
          }
        </div>

      ) : <div className="noclaimdata">
          <div className="searchbox">
            <div className="search-text-main">

            </div>
          </div>
          <div className="table">
            <table>
              <tr>
                <span>There are no claimable tasks available. Please check later</span>
                </tr>
                </table>
                </div>

      </div>}
       </div >
    // </Provider >
  )
};
export default Qadeshboard;


